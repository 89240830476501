<template>
    <v-container fluid class="overflow-x-hidden">
        <v-layout row class="frame">
            <v-flex d-flex text-center xs12 md6>
                 <div data-aos="flip-left" data-aos-duration="3000" class="about-img-frame">
                    <v-img src="../assets/prvoklasna-usluga.jpg" alt="about us"></v-img>
                 </div>
            </v-flex>
            <v-flex justify-center align-self-center text-left xs12 md6 >
                 <div data-aos="fade-left" class="about-text">
                    <h2>Želimo da istaknemo <br class="break"><span class="bold">prirodan i senzualan</span> <br class="break">izgled svake od naših dama <br class="break">tako da Vaš osmeh uvek<br class="break"> bude prisutan.</h2>
                    <div class="about-btn">
                        <a href="/contact">Zakažite termin</a><span><img src="../assets/arrow-light.png" alt="arrow-right"></span>
                    </div>
                 </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'AboutSingleImage',
    data: function() {
        return {}
    },
}
</script>


<style>

.overflow-x-hidden {
    overflow-x:hidden;
}

.about-img-frame{
    width: 100%;
}

.frame{
    background-color: #101010;
}

.about-text{
    text-align: center;
    color: #fff0e7;
}

.about-text p{
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.97px;
    text-align: center;
    
}

.about-text h2{
    font-family: "IvyMode Lt";
    font-size: 48px;
    font-weight: 300;
    line-height: 1.21;
    letter-spacing: 1.45px;
    margin: 63px 0 90px;
}

.bold{
    font-family: "IvyMode Semi Bd";
}

.about-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 81px;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.33px;
    color: #fff0e7;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

@media only screen and (max-width: 550px) {

    .about-img-frame img{
        background-image: url("../assets/philaser-mobile.jpg") center center / cover;
    }

    .about-text{
        text-align: center;
        padding: 60px 0;
    }

    .frame{
        display: flex;
        height: 100%;
        width: auto; 
    }

    .about-text p{
        font-size: 16px;
    }

    .about-text h2{
        font-size: 36px;
        font-family: "IvyMode Lt";
    }

    .about-btn{
        justify-content: center;
        margin-top: 31px;
    }   
}



</style>