<template>
    <div class="about-back">
        <v-container class="overflow-x-hidden">
            <div class="about-title">
                <p>O nama</p>
            </div>
            <div class="about-images">
                <div data-aos="fade-right" class="box-1">
                   <img src="../assets/phistudio_nora.png" class="logo-about" alt="Phi logo"> 
                   <img src="../assets/extraordinary.png" class="logo-about" alt="Phi extraordinary">
                </div>
                <div data-aos="zoom-in" class="box-2">
                    <img src="../assets/nora-about.png" class="nora-jakovljevic" alt="Nora Jakovljević">
                </div>
                <div data-aos="fade-left" class="box-3">
                    <img src="../assets/boldbrows.png" class="logo-about" alt="Phi boldbrows">  
                    <img src="../assets/phibrows-logo.png" class="logo-about" alt="Phibrows"> 
                    <img src="../assets/logophi.png" class="logo-about" alt="Phi Logo">
                </div>
            </div>

            <div class="about-txt-content">
                <div class="about-left">
                    <h2><span class="bold">Prvoklasna <br class="break">usluga</span> iz oblasti permanentne <br class="break">šminke</h2>
                </div>
                <div class="about-right">
                    <p> Mi smo salon kome je vaša sreća najbitnija. Posvećeno radimo na tome da krajnji rezultat bude upravo onaj sa kojim ćete izaći zadovoljni. 
                        Nalazimo se u samom srcu Beograda, u ulici <span class="bold">Gospodar Jovanova 43.</span> U prijatnom ambijentu možete se opustiti i prepustiti, 
                        sigurni da je naš stručni tim tu da podrži Vaše želje.
                    </p>
                    <p>
                        Desetogodišnje iskustvo koje posedujemo stavlja nas u sam vrh profesionalaca u PMU oblasti.  
                        Čuvamo vaše zdravlje, koristeći najsavremeniju tehnologiju i tehniku.
                    </p>
                </div>
            </div>
        </v-container>    
    </div>
</template>

<script>
export default {
    name: 'About',
    data: function() {
        return {}
    },
}
</script>

<style scoped>

.about-back{
    background-color: #ffe7d8;
    overflow-x:hidden;
}

.about-title{
    margin: 10px 0 18px 0;
    text-align: center;
    font-family: "IvyMode Lt";
    font-size: 66px;
    font-weight: 300;
    line-height: 1.06;
    letter-spacing: 1.83px;
    text-align: center;
    color: #000000;

}
.about-images{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    overflow-x:hidden;
}

.logo-about{
    width: 290px;
}

.nora-jakovljevic{
    height: 650px;
    width: auto;
}

.box-1,
.box-2{
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box-3,
.box-1{
    height: inherit;
    margin: 90px 0 30px;
    flex-direction: column;
    justify-content: space-around;
    overflow-x:hidden;
    display: flex;
}

.about-txt-content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 160px 0;
}

.about-left{
    font-family: "IvyMode Lt";
    font-size: 46px;
    font-weight: 300;
    line-height: 1.24;
    letter-spacing: 2px;
    color: #101010;
    width: 50%;
}

.about-right{
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    color: #000000;
    width: 39%;
}

.bold{
    font-family: "IvyMode Semi Bd";
}


@media only screen and (max-width: 550px) {
    .about-title{
        font-size: 36px;
        margin: 30px 0 30px;
    }

    img{
        width: 100%;
    }

    .box-1{
        height: inherit;margin: 30px 0 30px;
        justify-content: center;
    }

    .box-3{
        display: flex;
       align-items: center;
    }

    .logo-about{
        margin-bottom: 40px;
    }

    .logo-holder{
        left: 10%;
        top: 85%;
        width: 80%;
        height: auto;
        padding: 60px 20px;
    }

    .about-txt-content{
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
    }

    .about-txt-content{
        margin: 0;
        text-align: center;
    }

    .about-left{
        font-size: 21px;
        margin-bottom: 20px;
    }


    .about-right p{
        font-family: 'Quicksand', sans-serif;
    }
    
    .about-right{
        line-height: 1.38;
        font-size: 17px;
        font-weight: 500;
    }

    .about-right,
    .about-left{
        width: 100%;
    }
    
    
}


</style>