<template>
  <div class="aboutus">
    <About></About> 
    <AboutSingleImage background-url="hero.jpg" background-url-mobile="hero-mobile.jpg"></AboutSingleImage>
    <ImageSlider></ImageSlider>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue'
import AboutSingleImage from '@/components/AboutSingleImage.vue'
import ImageSlider from '@/components/ImageSlider.vue' 
import Footer from '@/components/Footer.vue'



export default {
  name: 'AboutUs',
  components: {
    About,
    AboutSingleImage,
    ImageSlider,
    Footer
  }
}
</script>