<template>
  <div class="back">
    <v-container fluid>
        <div class="slider-title">
            <h3>Salon</h3>
        </div>  
      <div class="carousel-wrapper">
        <VueSlickCarousel v-bind="settings"  ref="carousel">
          <div><img src="../assets/gallery/01.jpg" /></div>
          <div><img src="../assets/gallery/03.jpg" /></div>
          <div><img src="../assets/gallery/04.jpg" /></div>
          <div><img src="../assets/gallery/05.jpg" /></div>
          <div><img src="../assets/gallery/06.jpg" /></div>
          <div><img src="../assets/gallery/07.jpg" /></div>
          <div><img src="../assets/gallery/08.jpg" /></div>
          <div><img src="../assets/gallery/09.jpg" /></div>
          <div><img src="../assets/gallery/10.jpg" /></div>
          <div><img src="../assets/gallery/11.jpg" /></div>
        </VueSlickCarousel>
      </div> 
      <div class="arrows">
        <img src="../assets/arrow-black-left.png" alt="arrow left" @click="previous" />
        <img src="../assets/arrow-black-right.png" alt="arrow right" @click="next" />
      </div>
    </v-container>
  </div>
</template>


<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import "vue-slick-carousel/dist/vue-slick-carousel.css";

  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'



  export default {
    name: 'ImageSlider',
    components: { VueSlickCarousel },
    data: function(){
      return {
        settings: {
          "arrows": false,
          "centerMode": true,
          "dots": false,
          "infinite": true,
          "speed": 1000,
          "slidesToShow": 2,
          "slidesToScroll": 4,
          "initialSlide": 0,
          "responsive": [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "infinite": true,
                "dots": true,
                centerMode: false,
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "initialSlide": 2,
                centerMode: false,
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                centerMode: false,
              }
            }
          ]
        }
      }
    },
    methods:{
      next() {
        this.$refs.carousel.next();
      },
      previous() {
        this.$refs.carousel.prev();
      }
    }
  }
</script>

<style scoped>

.back{
  background-color: #ffe7d8;
  padding-bottom: 120px;
}

.slider-title h3{
    margin-top: 100px;
    font-family: "IvyMode Lt";
    font-size: 66px;
    font-weight: 300;
    line-height: 1.06;
    letter-spacing: 1.83px;
    text-align: center;
    color: #000000;
}

/* .carousel-section {
  height: 955px;
  width: 1440px;
  position: relative;
} */

.carousel-wrapper {
  padding: 150px 0 30px 160px;
  margin: 0 auto;
}

.arrows{
  padding-left: 210px;
}

.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrows {
  width: 100px;
  height: 60px;
  left: 14%;
  top: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}

img {
  display: block;
  padding-right: 35px;
}

img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 550px) {

  .back{
    padding-bottom: 0px;
  }

  .slider-title h3{
    margin-top: 40px;
    font-size: 36px;
  }
   
  .carousel-wrapper {
    padding: 60px 20px 20px;
    margin: 0 auto;
    position: relative;
  }

  .arrows{
    padding-left: 25px;
  }

}


</style>